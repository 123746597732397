<template>
  <el-dialog :visible.sync="status" custom-class="add-user" @close="close">
    <label class="info-box">
      <img class="avatar" :src="formartImageUrl(data.thumb)" alt />
      <span>{{ data.name }}</span>
    </label>
    <el-input type="textarea" v-model="tips"></el-input>
    <el-button
      type="primary"
      round
      icon="el-icon--send"
      @click="submitFunc"
    ></el-button>
  </el-dialog>
</template>
<script>
export default {
  name: "Add",
  data() {
    return {
      status: false,
      tips: ""
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    watchShow() {
      return this.show;
    }
  },
  watch: {
    watchShow: {
      handler(val) {
        this.status = val;
      },
      dreep: true
    }
  },
  created() {
    this.status = this.show;
  },
  methods: {
    submitFunc() {
      this.$emit("send", this.tips);
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>
<style lang="stylus">
.add-user {
  width: 350px;
  border-radius: 5px;

  .el-dialog__header {
    .el-dialog__headerbtn {
      i {
        font-size: 1.5rem;
      }
    }
  }

  .el-dialog__body {
    padding: 0 20px;

    .el-textarea {
      min-height: 100px;

      .el-textarea__inner {
        min-height: 100px !important;
      }
    }

    .el-button {
      padding: 0.25rem 2.5rem;
      margin: 0.9rem 0;
      float: right;

      i {
        font-size: 1.5rem;
        color: #ffffff;
      }
    }
  }

  .info-box {
    display: flex;
    flex-flow: row;
    align-items: center;
    margin: 0.5rem 0;

    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      margin-right: 0.5rem;
    }

    span {
      margin: 0;
      color: #33cc66;
    }
  }
}
</style>
