var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.status, "custom-class": "add-user" },
      on: {
        "update:visible": function($event) {
          _vm.status = $event
        },
        close: _vm.close
      }
    },
    [
      _c("label", { staticClass: "info-box" }, [
        _c("img", {
          staticClass: "avatar",
          attrs: { src: _vm.formartImageUrl(_vm.data.thumb), alt: "" }
        }),
        _c("span", [_vm._v(_vm._s(_vm.data.name))])
      ]),
      _c("el-input", {
        attrs: { type: "textarea" },
        model: {
          value: _vm.tips,
          callback: function($$v) {
            _vm.tips = $$v
          },
          expression: "tips"
        }
      }),
      _c("el-button", {
        attrs: { type: "primary", round: "", icon: "el-icon--send" },
        on: { click: _vm.submitFunc }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }