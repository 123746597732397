<template>
  <div class="user-info-card">
    <el-upload
      v-if="$store.getters.userInfo.id === userinfo.id && curPage === 'user'"
      class="avatar-uploader"
      action="/"
      :http-request="handlerUploadFile"
      :show-file-list="false"
    >
      <div class="photo">
        <i class="el-icon-camera"></i>
      </div>
      <img
        v-if="userinfo.thumb"
        class="thumb"
        :src="formartImageUrl(userinfo.thumb)"
      />
      <i v-else class="el-icon--plus avatar-uploader-icon thumb"></i>
    </el-upload>
    <template v-else>
      <img
        class="thumb"
        v-if="userinfo.thumb"
        :src="formartImageUrl(userinfo.thumb)"
        @click="handlePreview()"
        :is-viewer-image ="viewerFlag"
      />
      <i v-else class="thumb el-icon-user-solid"></i>
    </template>

    <label class="name_country">
      <span
        class="name"
        @click="routeJump({ name: 'personalCenter', params: { id: userinfo.id } })"
        >{{ userinfo.name }}</span
      >
      <span class="country">
        <img
          v-if="userinfo.countryCode && userinfo.countryCode !== '0'"
          class="country"
          :src="getCountryIcon(userinfo.countryCode)"
        />
        {{ userinfo.country }}
      </span>
    </label>
    <span class="tag" v-if="userinfo.i_can_do">{{ userinfo.i_can_do }}</span>
    <span class="ucid" v-if="userinfo.uc_id">ID：{{ userinfo.uc_id }}</span>
    <action
      class="action"
      v-if="userinfo.actionData"
      :action-data="userinfo.actionData"
      ref="actionItem"
      @fource="handlerRepplay"
    />
    <span class="btns" v-if="showbtn">
      <i class="el-icon--chat" @click="chatWithAuthor(userinfo, 'user')"></i>
      <i
        class="el-icon--addfriend default"
        v-if="userinfo.id !== $store.getters.userInfo.id && !userinfo.friend"
        @click="addFriendConfirm()"
      ></i>
      <i
        v-else-if="userinfo.friend && $store.getters.userInfo.id"
        class="el-icon--delMember default"
        @click="confirm(null, null, 'el-icon--delMember', deleteFriend)"
      ></i>
      <i
        v-else-if="userinfo.id === $store.getters.userInfo.id"
        class="el-icon--editw default"
        @click="
          jumpPage({ name: 'publish', params: { type: 'my', id: userinfo.id } })
        "
      ></i>
    </span>
    <!-- <el-dialog :visible.sync="showTips" :show-close="false" custom-class="tips" :modal="false">
      <i class="el-icon-check"></i>
    </el-dialog>-->
    <tips v-if="showTips" :show-tips="showTips"></tips>
    <AddTip
      v-if="show"
      :show="show"
      :data="userinfo"
      @send="addFriendFunc"
      @close="addFriendConfirm"
    ></AddTip>
  </div>
</template>
<script>
import action from "@/components/business/web-site/action";
import { getUploadToken } from "@/api/qiniu";
import * as qiniu from "qiniu-js";
import { env, parseTime } from "@/utils";
import { publicDo } from "@/api/dado";
import AddTip from "@/components/business/web-site/dialog/add";
import tips from "@/components/business/web-site/dialog/tip";
export default {
  name: "UserInfoCard",
  data() {
    return {
      viewerFlag: true,
      content: "",
      curPage: "",
      showTips: false,
      show: false
    };
  },
  props: {
    userinfo: {
      type: Object,
      default: () => {
        return {};
      }
    },
    showbtn: {
      type: Boolean,
      default: true
    }
  },
  components: { action, AddTip, tips },
  created() {
    this.curPage = this.$route.name;
  },
  methods: {
    // 图片预览
    handlePreview() {
      if( this.curPage !== 'user' ){
        this.routeJump({ name: 'personalCenter', params: { id: this.userinfo.id } });
      } else {
        try {
          const viewer = this.$viewer(this.$el, {
            filter(image) {
              return image.getAttribute("is-viewer-image");
            },
            hidden: function() {
              viewer.destroy();
            }
          });
        } catch (error) {}
      }
    },
    addFriendConfirm() {
      // addFriendFunc
      if (this._checkUserLogin()) this.show = !this.show;
    },
    handlerRepplay() {
      this.$emit("fource", { fource: true, active: 3 });
    },
    async handlerUploadFile(files) {
      await this.handlerUploadFileToQiniu(files);
    },
    async updateThumb() {
      let params = {
        funcId: "hex_helome_updateHelogigProviderHeadFunction",
        provider_id: this.$store.getters.userInfo.id,
        profile_photo: this.userinfo.thumb,
        table_name_replace: localStorage.getItem("langCode")
      };
      let result = await publicDo(params, "public/data.do").catch(e => {
        console.log(
          e +
            "from src/components/business/web-site/user-info-card/index.vue:285:18:"
        );
      });
      if (result.message && result.message === "success") {
        let userinfo = this.$store.getters.userInfo;
        userinfo.avatar = this.userinfo.thumb;
        this.$store.commit("setUserinfo",userinfo)
      }
    },
    async handlerUploadFileToQiniu(files) {
      let file = files.file;

      const isvideo = ["audio", "video"];
      const bucket =
        process.env.NODE_ENV == "development"
          ? "tfile"
          : isvideo.some(val => file.type.indexOf(val) !== -1)
          ? "video"
          : "imfile";

      const config = await getUploadToken(file.name, bucket);
      // console.log(config, "/config");

      const { randomFileName, token } = config.data;
      const [year, month] = parseTime(new Date(), "{y}-{m}").split("-");

      const observable = qiniu.upload(
        file,
        `${year}/${month}/${randomFileName}`,
        token,
        {
          fname: file.name
        },
        {
          useCdnDomain: true,
          region: qiniu.region.z2
        }
      );
      observable.subscribe({
        next: uploadEvent => {
          uploadEvent.total.percent.toFixed(2);
        },
        error: error => {
          console.log("error", error);
        },
        complete: async res => {
          const baseurl = {
            tfile: env("UPLOAD_IMFILE"),
            video: env("UPLOAD_VIDEO"),
            imfile: env("UPLOAD_IMFILE")
          };
          // console.log(res, "/res");

          // item.path
          this.userinfo.thumb = `${baseurl[bucket]}/${res.key}`;
          // await this.updateThumb();
          let userinfo = this.$store.getters.userInfo;
          userinfo.avatar = this.userinfo.thumb;
          this.$store.commit("setUserinfo", userinfo);
          await this.handlerUpAvatar(this.userinfo.thumb);
        }
      });
    },
    async handlerUpAvatar(url) {
      const params = {
        userId: this.userinfo.id,
        profilePic: url
      };
      await this.$http({
        url: "/rest/personalCenter/v3/updateProfilePic",
        params
      });
    },
    async deleteFriend() {
      let params = {
        userId: this.$store.getters.userInfo.id,
        friendId: this.userinfo.id,
        language: localStorage.getItem("langCode")
      };
      let result = await this.$http({
        url: "rest/friend/remove/" + this.userinfo.id,
        params,
        method: "post",
        withCredentials: false
      }).catch(e => {
        console.log(
          e +
            "from src/components/business/web-site/user-info-card/index.vue:255:18:"
        );
      });
      if (result) {
        this.userinfo.friend = !this.userinfo.friend;
        // location.reload();
        // console.log("//delete success");
      }
    },
    _checkUserLogin() {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return false;
      }
      return true;
    },
    async addFriendFunc(msg) {
      if (this._checkUserLogin()) {
        this.show = !this.show;
        let params = {
          senderId: this.$store.getters.userInfo.id,
          targetId: this.userinfo.id,
          tipCode: 207,
          language: localStorage.getItem("langCode") || "1",
          tipContent: msg || ""
        };
        let result = await this.$http({
          url: "rest/friend/add",
          params,
          method: "post",
          withCredentials: false
        }).catch(e => {
          console.log(
            e +
              "from src/components/business/web-site/user-info-card/index.vue:285:18:"
          );
        });

        if (result && result.code === 200) {
          this.showTips = !this.showTips;
          setTimeout(() => {
            this.showTips = !this.showTips;
          }, 2000);
        } else {
          this.$emit("errorRefsh");
        }
      }
    }
  }
};
</script>
<style lang="stylus">
.user-info-card {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 0.9rem;

  .tips {
    width: 70px;
    height: 70px;

    .el-dialog__body, .el-dialog__header {
      padding: 0;
      margin: 0;
    }

    .el-dialog__body {
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      .el-icon-check {
        font-size: 2rem;
        color: #33cc66;
      }
    }
  }

  .avatar-uploader {
    img {
      border-radius: 90px;
      z-index: 80;
    }

    display: flex;
    position: relative;

    &:hover {
      .photo {
        opacity: 1;
      }
    }

    .photo {
      opacity: 0;
      position: absolute;
      width: 100px;
      height: 100px;
      background: rgba(0, 0, 0, 0.5);
      font-size: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;

      i {
        color: #efefef;
        font-size: 2.5rem;
      }
    }
  }

  span {
    margin: 1.5rem 0 0;

    &.ucid {
      margin: 0.5rem 0;
      max-width: 300px;
      word-break: break-all;
    }
  }

  .name_country {
    margin: 0.5rem 0;
    max-width: 300px;
    word-break: break-all;

    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    .name {
      // overflow: hidden;
      // white-space: nowrap;
      // text-overflow: ellipsis;
    }

    img {
      margin-left: 0.5rem;
    }
  }

  .thumb {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 100px;
    background: #f5f5f5;
    text-align: center;
    vertical-align: middle;
    line-height: 100px;
  }

  i {
    font-size: 2rem;
    color: #999999;
  }

  .name {
    font-size: 20px;
    color: #33cc66;
  }

  .country {
    img {
      width: 16px;
      object-fit: cover;
    }

    font-size: 12px;
  }

  ul {
    &.action {
      margin: 0.5rem 0 0.9rem;
    }
  }

  .tag {
    font-size: 16px;
    margin: 0.5rem 0;
    color: #666666;
  }

  .btns {
    display: flex;
    flex-flow: row;
    width: 100%;

    i {
      flex: 1;
      text-align: center;
      font-size: 24px;
      padding: 0.3rem 0.2rem;
      border-radius: 2rem;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      &.el-icon--chat {
        background: #33cc66;
        color: #ffffff;
        margin-right: 0.8rem;
      }

      &.default {
        border: 2px solid #eeeeee;
        box-sizing: border-box;
        color: #666666;
      }
    }
  }
}
</style>
