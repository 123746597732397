var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-info-card" },
    [
      _vm.$store.getters.userInfo.id === _vm.userinfo.id &&
      _vm.curPage === "user"
        ? _c(
            "el-upload",
            {
              staticClass: "avatar-uploader",
              attrs: {
                action: "/",
                "http-request": _vm.handlerUploadFile,
                "show-file-list": false
              }
            },
            [
              _c("div", { staticClass: "photo" }, [
                _c("i", { staticClass: "el-icon-camera" })
              ]),
              _vm.userinfo.thumb
                ? _c("img", {
                    staticClass: "thumb",
                    attrs: { src: _vm.formartImageUrl(_vm.userinfo.thumb) }
                  })
                : _c("i", {
                    staticClass: "el-icon--plus avatar-uploader-icon thumb"
                  })
            ]
          )
        : [
            _vm.userinfo.thumb
              ? _c("img", {
                  staticClass: "thumb",
                  attrs: {
                    src: _vm.formartImageUrl(_vm.userinfo.thumb),
                    "is-viewer-image": _vm.viewerFlag
                  },
                  on: {
                    click: function($event) {
                      return _vm.handlePreview()
                    }
                  }
                })
              : _c("i", { staticClass: "thumb el-icon-user-solid" })
          ],
      _c("label", { staticClass: "name_country" }, [
        _c(
          "span",
          {
            staticClass: "name",
            on: {
              click: function($event) {
                return _vm.routeJump({
                  name: "personalCenter",
                  params: { id: _vm.userinfo.id }
                })
              }
            }
          },
          [_vm._v(_vm._s(_vm.userinfo.name))]
        ),
        _c("span", { staticClass: "country" }, [
          _vm.userinfo.countryCode && _vm.userinfo.countryCode !== "0"
            ? _c("img", {
                staticClass: "country",
                attrs: { src: _vm.getCountryIcon(_vm.userinfo.countryCode) }
              })
            : _vm._e(),
          _vm._v("\n      " + _vm._s(_vm.userinfo.country) + "\n    ")
        ])
      ]),
      _vm.userinfo.i_can_do
        ? _c("span", { staticClass: "tag" }, [
            _vm._v(_vm._s(_vm.userinfo.i_can_do))
          ])
        : _vm._e(),
      _vm.userinfo.uc_id
        ? _c("span", { staticClass: "ucid" }, [
            _vm._v("ID：" + _vm._s(_vm.userinfo.uc_id))
          ])
        : _vm._e(),
      _vm.userinfo.actionData
        ? _c("action", {
            ref: "actionItem",
            staticClass: "action",
            attrs: { "action-data": _vm.userinfo.actionData },
            on: { fource: _vm.handlerRepplay }
          })
        : _vm._e(),
      _vm.showbtn
        ? _c("span", { staticClass: "btns" }, [
            _c("i", {
              staticClass: "el-icon--chat",
              on: {
                click: function($event) {
                  return _vm.chatWithAuthor(_vm.userinfo, "user")
                }
              }
            }),
            _vm.userinfo.id !== _vm.$store.getters.userInfo.id &&
            !_vm.userinfo.friend
              ? _c("i", {
                  staticClass: "el-icon--addfriend default",
                  on: {
                    click: function($event) {
                      return _vm.addFriendConfirm()
                    }
                  }
                })
              : _vm.userinfo.friend && _vm.$store.getters.userInfo.id
              ? _c("i", {
                  staticClass: "el-icon--delMember default",
                  on: {
                    click: function($event) {
                      return _vm.confirm(
                        null,
                        null,
                        "el-icon--delMember",
                        _vm.deleteFriend
                      )
                    }
                  }
                })
              : _vm.userinfo.id === _vm.$store.getters.userInfo.id
              ? _c("i", {
                  staticClass: "el-icon--editw default",
                  on: {
                    click: function($event) {
                      return _vm.jumpPage({
                        name: "publish",
                        params: { type: "my", id: _vm.userinfo.id }
                      })
                    }
                  }
                })
              : _vm._e()
          ])
        : _vm._e(),
      _vm.showTips
        ? _c("tips", { attrs: { "show-tips": _vm.showTips } })
        : _vm._e(),
      _vm.show
        ? _c("AddTip", {
            attrs: { show: _vm.show, data: _vm.userinfo },
            on: { send: _vm.addFriendFunc, close: _vm.addFriendConfirm }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }